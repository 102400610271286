import { mutations, state as parentState } from '~/sites/mimi/store/locale'

const state = () => ({
  ...parentState(),
  alternativeDomains: [
    { language: 'cs', domain: 'https://mimi.nobilis.cz' },
    { language: 'sk', domain: 'https://mimi.nobilis-tilia.sk' },
  ],
  canonicalDomains: [{ language: 'sk', domain: 'https://mimi.nobilis-tilia.sk' }],
})

export { mutations, state }
