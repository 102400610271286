import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _c6da4650 = () => interopDefault(import('../sites/mimisk/pages/search/index.vue' /* webpackChunkName: "sites/mimisk/pages/search/index" */))
const _9769c04c = () => interopDefault(import('../sites/mimisk/pages/articleList/index.vue' /* webpackChunkName: "sites/mimisk/pages/articleList/index" */))
const _bfdcd84c = () => interopDefault(import('../sites/mimisk/pages/sitemapconfig.vue' /* webpackChunkName: "sites/mimisk/pages/sitemapconfig" */))
const _4d4eff18 = () => interopDefault(import('../sites/mimisk/pages/articleTag/index.vue' /* webpackChunkName: "sites/mimisk/pages/articleTag/index" */))
const _68ab9df5 = () => interopDefault(import('../sites/mimisk/pages/index.vue' /* webpackChunkName: "sites/mimisk/pages/index" */))
const _0828909a = () => interopDefault(import('../sites/mimisk/pages/author/_slug.vue' /* webpackChunkName: "sites/mimisk/pages/author/_slug" */))
const _7d7b97d4 = () => interopDefault(import('../sites/mimisk/pages/article/_slug.vue' /* webpackChunkName: "sites/mimisk/pages/article/_slug" */))
const _7bc7f612 = () => interopDefault(import('../sites/mimisk/pages/page/_slug.vue' /* webpackChunkName: "sites/mimisk/pages/page/_slug" */))
const _50bc41a8 = () => interopDefault(import('../sites/mimisk/pages/articleTag/_slug.vue' /* webpackChunkName: "sites/mimisk/pages/articleTag/_slug" */))
const _03d84f95 = () => interopDefault(import('../sites/mimisk/pages/articleList/_slug/index.vue' /* webpackChunkName: "sites/mimisk/pages/articleList/_slug/index" */))
const _66f4fcad = () => interopDefault(import('../sites/mimisk/pages/_slug.vue' /* webpackChunkName: "sites/mimisk/pages/_slug" */))
const _2243a72c = () => interopDefault(import('../sites/mimisk/pages/articleList/_slug/_child.vue' /* webpackChunkName: "sites/mimisk/pages/articleList/_slug/_child" */))
const _978d36fc = () => interopDefault(import('../sites/mimisk/pages/_.vue' /* webpackChunkName: "sites/mimisk/pages/_" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/hladat/",
    component: _c6da4650,
    pathToRegexpOptions: {"strict":true},
    name: "search___sk"
  }, {
    path: "/rubriky/",
    component: _9769c04c,
    pathToRegexpOptions: {"strict":true},
    name: "articleList___sk"
  }, {
    path: "/sitemapconfig/",
    component: _bfdcd84c,
    pathToRegexpOptions: {"strict":true},
    name: "sitemapconfig___sk"
  }, {
    path: "/tagy/",
    component: _4d4eff18,
    pathToRegexpOptions: {"strict":true},
    name: "articleTag___sk"
  }, {
    path: "/",
    component: _68ab9df5,
    pathToRegexpOptions: {"strict":true},
    name: "index___sk"
  }, {
    path: "/autori/:slug/",
    component: _0828909a,
    pathToRegexpOptions: {"strict":true},
    name: "author-slug___sk"
  }, {
    path: "/clanok/:slug/",
    component: _7d7b97d4,
    pathToRegexpOptions: {"strict":true},
    name: "article-slug___sk"
  }, {
    path: "/stranka/:slug/",
    component: _7bc7f612,
    pathToRegexpOptions: {"strict":true},
    name: "page-slug___sk"
  }, {
    path: "/tag/:slug/",
    component: _50bc41a8,
    pathToRegexpOptions: {"strict":true},
    name: "articleTag-slug___sk"
  }, {
    path: "/:slug/",
    component: _03d84f95,
    pathToRegexpOptions: {"strict":true},
    name: "articleList-slug___sk"
  }, {
    path: "/:slug/",
    component: _66f4fcad,
    pathToRegexpOptions: {"strict":true},
    name: "slug___sk"
  }, {
    path: "/:slug/:child/",
    component: _2243a72c,
    pathToRegexpOptions: {"strict":true},
    name: "articleList-slug-child___sk"
  }, {
    path: "/*/",
    component: _978d36fc,
    pathToRegexpOptions: {"strict":true},
    name: "all___sk"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
